import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Homepage from './page/Homepage/HomePage';
import Footer from './components/Footer/Footer';
import ProfessorJJB1 from './page/Professors/ProfessorJJB1';
import NavBar from './components/NavBar/NavBar';
import MainNav from './components/MainNav/MainNav';
import LogIn from './page/LogIn/LogIn';
import SignIn from './page/SignIn/SignIn';
import Lessons from './page/LessonsPage/Lessons';
import LessonDetails from "./page/LessonDetails/LessonDetails";
import Lesson from "./data/lesson.json";
import styles from './App.module.scss';
import Offers from './page/Offers/Offers';
import ForgotPassword from './page/ForgotPassword/ForgotPassword';
import ResetPassword from './page/ResetPassword/ResetPassword';
import Success from './page/SuccessPage/SuccessPage';
import Error from './page/Error/Error';
import Gratuite from './page/Gratuites/Gratuite';
import Renforcement from './page/Renforcement/Renforcement';
import RenfoDetails from './page/RenfoDetails/RenfoDetails';
import Module from './page/Module/Module';
import GardeAssise from './page/GardeAssise/garde_assise';
import CentKg from './page/100Kg/100Kg';
import Controle from './page/Controle/controle';
import Dos from './page/Dos/Dos';
import GardeFermee from './page/GardeFermée/GardeFermee';
import Monte from './page/Montée/Monte';
import PassageRiva from './page/PassageRiva/PassageRiva';

function App() {
  const [lessonToDisplay, setLessonToDisplay] = useState(Lesson[0]);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Afficher le pop-up dès que l'utilisateur charge ou rafraîchit la page
    setShowPopup(true);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false); // Fermer le pop-up lorsque l'utilisateur clique sur le bouton
  };;

  return (
    <div className={` ${styles.appContainer}`}>
      <MainNav />
      <NavBar />

      {/* Pop-up qui s'affiche uniquement lors de la première visite */}
{showPopup && (
  <div className={styles.popup}>
    <div className={styles.fireworksContainer}>
      {/* Générer plusieurs feux d'artifice avec des positions aléatoires */}
      {Array.from({ length: 15 }).map((_, index) => (
        <div
          key={index}
          className={styles.firework}
          style={{
            '--random-x': Math.random(),
            '--random-y': Math.random(),
          }}
        ></div>
      ))}
    </div>
    <div className={styles.popupContent}>
    <p className={styles.popupPara}>
      Nouvelle arrivée chez <strong className={styles.strong}>Drill-it</strong> ! 🎉 <br /><br />
      Nous avons le plaisir d’accueillir <strong className={styles.strong}>Kévin Lépine</strong>, expert en JJB, qui rejoint notre équipe pour vous faire progresser encore plus. 💪🥋<br /><br />
      Découvrez sans plus attendre <strong className={styles.strong}>22 vidéos exclusives</strong> pour perfectionner votre technique et enrichir votre entraînement. <br /> <br />
      Des <strong className={styles.strong}>nouveaux modules</strong> sont en ligne ! <br />
      À vos tatamis ! 🔥
    </p>

     <button className={styles.btnpop} onClick={handleClosePopup}>Fermer</button>
    </div>
  </div>
)}



      <div className={styles.content}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/ProfessorJJB1" element={<ProfessorJJB1 />} />
          <Route path="/gratuite" element={<Gratuite lessonToDisplay={lessonToDisplay} setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/renforcement" element={<Renforcement lessonToDisplay={lessonToDisplay} setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/renforcement/:id" element={<RenfoDetails lessonToDisplay={lessonToDisplay} setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/module" element={<Module lessonToDisplay={lessonToDisplay} setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/Lessons" element={<Lessons setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/Lessons/:id" element={<LessonDetails lessonToDisplay={lessonToDisplay} setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/garde_assise" element={<GardeAssise setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/100kg" element={<CentKg setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/dos" element={<Dos setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/garde_ferme" element={<GardeFermee setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/monte" element={<Monte setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/riva" element={<PassageRiva setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/controle" element={<Controle setLessonToDisplay={setLessonToDisplay} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/LogIn" element={<LogIn />} />
          <Route path="/signup" element={<SignIn />} />
          <Route path="/success" element={<Success />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;

