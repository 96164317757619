import styles from './Module.module.scss';
import { Link } from "react-router-dom";

export default function Module() {
    return (
        <div className={styles.module}>
            <div className={styles.module1}>
            <Link to="/riva">
                    <img className={`Thiago ${styles.img}`} src="/miniature/module/Tyrone2.png" alt="Module 1" />
            </Link>
            <Link to="/controle">
                    <img className={`Thiago ${styles.img}`} src="/miniature/module/Thiagoctrl.png" alt="Module 1" />
            </Link>
            <Link to="/garde_assise">
                    <img className={`Thiago ${styles.img}`} src="/miniature/module/garde-assise1.jpg" alt="Module 1" />
            </Link>
            <Link to="/100kg">
                    <img className={`Thiago ${styles.img}`} src="/miniature/module/thiago100KG.png" alt="Module 2" />
            </Link>
            <Link to="/dos">
                    <img className={`Thiago ${styles.img}`} src="/miniature/module/dos.png" alt="Module 1" />
            </Link>
            <Link to="/garde_ferme">
                    <img className={`Thiago ${styles.img}`} src="/miniature/module/GardeFerme.png" alt="Module 1" />
            </Link>
            <Link to="/monte">
                    <img className={`Thiago ${styles.img}`} src="/miniature/module/monte.png" alt="Module 1" />
            </Link>
            </div>
        </div>
    )
}
