import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './100Kg.module.scss';
import LessonCard from '../../components/LessonCard/LessonCard';
import Lesson from '../../data/lesson.json';

export default function CentKg({ setLessonToDisplay }) {
  const lessonsPerPage = 20;
  const location = useLocation();
  const navigate = useNavigate();

  // Liste des IDs des leçons dans l'ordre souhaité
  const orderedLessonIds = [27, 26, 3, 44, 45, 48, 95, 1, 88];

  // Fonction pour obtenir un paramètre de l'URL
  const getQueryParam = (key, defaultValue) => {
    const params = new URLSearchParams(location.search);
    return params.get(key) || defaultValue;
  };

  // Obtenez les filtres et la page depuis les query parameters
  const [currentPage, setCurrentPage] = useState(Number(getQueryParam('page', 1)));
  const [selectedCategory, setSelectedCategory] = useState(getQueryParam('category', 'All'));
  const [selectedLevel, setSelectedLevel] = useState(getQueryParam('level', 'All'));

  // Mettre à jour l'URL lorsque les filtres ou la page changent
  const updateQueryParams = useCallback((newPage, newCategory, newLevel) => {
    const params = new URLSearchParams();
    if (newPage) params.set('page', newPage);
    if (newCategory) params.set('category', newCategory);
    if (newLevel) params.set('level', newLevel);
    navigate(`?${params.toString()}`);
  }, [navigate]);

  useEffect(() => {
    updateQueryParams(currentPage, selectedCategory, selectedLevel);
  }, [currentPage, selectedCategory, selectedLevel, updateQueryParams]);

  // Filtrer et trier les leçons selon l'ordre des IDs fournis
  const filteredLessons = Lesson.filter((lesson) => {
    return (
      lesson.module === "100kg" && 
      orderedLessonIds.includes(lesson.id) &&
      (selectedCategory === 'All' || lesson.category === selectedCategory) &&
      (selectedLevel === 'All' || lesson.level === selectedLevel)
    );
  }).sort((a, b) => orderedLessonIds.indexOf(a.id) - orderedLessonIds.indexOf(b.id)); 

  // Calculate pagination based on filtered lessons
  const totalPages = Math.ceil(filteredLessons.length / lessonsPerPage);
  const startIndex = (currentPage - 1) * lessonsPerPage;
  const currentLessons = filteredLessons.slice(startIndex, startIndex + lessonsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const applyCategoryFilter = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to first page after applying filter
  };

  const applyLevelFilter = (level) => {
    setSelectedLevel(level);
    setCurrentPage(1); // Reset to first page after applying filter
  };

  return (
    <div className={styles.Lessons}>
      {/* Filter Buttons */}
      <img className={`Thiago ${styles.img}`} src="/miniature/module/thiago100KG.png" alt="Module 1" />

      <div className={styles.titleContainer}>
        <h1 className={styles.h1}>100 Kilos</h1>
        <h2 className={styles.h2}>
          Thiago Goiabeira
        </h2>
      </div>

      {/* Display lessons */}
      <div className={styles.lessonList}>
        {currentLessons.map((lesson, index) => (
          <LessonCard 
            key={lesson.id}
            lesson={lesson} 
            setLessonToDisplay={setLessonToDisplay} 
            basePath="Lessons" 
            onClick={() => window.scrollTo(0, 0)} 
          />
        ))}
      </div>

      {/* Pagination Buttons */}
      <div className={styles.pagination}>
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={styles.paginationButton}
        >
          Précédent
        </button>
        <span className={styles.pageInfo}>
          Page {currentPage} sur {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={styles.paginationButton}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
