import styles from "./Renforcement.module.scss";
import LessonCard from '../../components/LessonCard/LessonCard';
import Lesson from '../../data/renfo.json';
import React, { useState, useEffect, useCallback  } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Renforcement({ lessonToDisplay, setLessonToDisplay }) {
  const lessonsPerPage = 15;
  const location = useLocation();
  const navigate = useNavigate();

  // Fonction pour obtenir un paramètre de l'URL
  const getQueryParam = (key, defaultValue) => {
    const params = new URLSearchParams(location.search);
    return params.get(key) || defaultValue;
  };

  // Obtenez les filtres et la page depuis les query parameters
  const [currentPage, setCurrentPage] = useState(Number(getQueryParam('page', 1)));
  const [selectedCategory, setSelectedCategory] = useState(getQueryParam('category', 'All'));
  const [selectedLevel, setSelectedLevel] = useState(getQueryParam('level', 'All'));

  // Mettre à jour l'URL lorsque les filtres ou la page changent
  const updateQueryParams = useCallback((newPage, newCategory, newLevel) => {
    const params = new URLSearchParams();
    if (newPage) params.set('page', newPage);
    if (newCategory) params.set('category', newCategory);
    if (newLevel) params.set('level', newLevel);
    navigate(`?${params.toString()}`);
  }, [navigate]);

  useEffect(() => {
    updateQueryParams(currentPage, selectedCategory, selectedLevel);
  }, [currentPage, selectedCategory, selectedLevel, updateQueryParams]);

  // Filter lessons by category and level
  const filteredLessons = Lesson.filter((lesson) => {
    return (
      (selectedCategory === 'All' || lesson.category === selectedCategory) &&
      (selectedLevel === 'All' || lesson.level === selectedLevel)
    );
  });

  // Calculate pagination based on filtered lessons
  const totalPages = Math.ceil(filteredLessons.length / lessonsPerPage);
  const startIndex = (currentPage - 1) * lessonsPerPage;
  const currentLessons = filteredLessons.slice(startIndex, startIndex + lessonsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const applyCategoryFilter = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to first page after applying filter
  };

  const applyLevelFilter = (level) => {
    setSelectedLevel(level);
    setCurrentPage(1); // Reset to first page after applying filter
  };
  
    return (
      <div className={styles.Lessons}>
        {/* Filter Buttons */}
        <div className={styles.filterButtons}>
  
          <div className={styles.levelButtons}>
            <button 
              onClick={() => applyLevelFilter('All')} 
              className={`${styles.filterButton} ${selectedLevel === 'All' ? styles.active : ''}`}
            >
              Tous
            </button>
            <button 
              onClick={() => applyLevelFilter('Débutant')} 
              className={`${styles.filterButton} ${selectedLevel === 'Débutant' ? styles.active : ''}`}
            >
              Débutant
            </button>
            <button 
              onClick={() => applyLevelFilter('Intermédiaire')} 
              className={`${styles.filterButton} ${selectedLevel === 'Intermédiaire' ? styles.active : ''}`}
            >
              Intermédiaire
            </button>
            <button 
              onClick={() => applyLevelFilter('Confirmé')} 
              className={`${styles.filterButton} ${selectedLevel === 'Confirmé' ? styles.active : ''}`}
            >
              Confirmé
            </button>
          </div>
        </div>
  
        <div className={styles.titleContainer}>
          <h1 className={styles.h1}>Renforcement</h1>
        </div>
  
        {/* Display lessons */}
        <div className={styles.lessonList}>
          {currentLessons.map((lesson, index) => (
            <LessonCard 
            lesson={lesson} 
            setLessonToDisplay={setLessonToDisplay} 
            basePath="renforcement" 
          />
          ))}
        </div>
  
        {/* Pagination Buttons */}
        <div className={styles.pagination}>
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={styles.paginationButton}
          >
            Précédent
          </button>
          <span className={styles.pageInfo}>
            Page {currentPage} sur {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={styles.paginationButton}
          >
            Suivant
          </button>
        </div>
      </div>
    );
  }
  