import styles from './Professesors.module.scss';

export default function ProfessorJJB1() {
  return (
    <div className={`professsorJJB1 ${styles.ProfessorJJB1}`} id='#ProfessorJJB1'>
      <div><h1 className={`h1prof ${styles.h1prof}`}>Thiago Goiabeira</h1></div>
      <div  className={`professsor ${styles.professsor}`}>
        <img className={`Thiago ${styles.Thiago}`} src="thiago10.jpg" alt="Thiago" />
        <p className={`pJJB ${styles.pJJB}`}>
          Né à Fortaleza, au Brésil, Thiago découvre le Jiu-Jitsu en 2000, dans la célèbre académie Ribeiro, auprès de Xande Ribeiro (hall of famer ADCC). <br /> <br />
          Aujourd'hui ceinture noire 4ème degré, il est le head coach de l'académie Respire Jiu-Jitsu et l'ambassadeur Six Blades en France métropolitaine. <br /><br />
          Compétiteur dévoué, il participe et remporte de grandes compétitions: Champion de France 2019, 2020, 2021,2022 ,2023, 2024, Champion Europe 2019, Vice-Champion Europe 2022, 3ème AJP 2023 et Champion du Monde 2024 à l'AJP Tour.<br /> <br />
          Implanté en Bretagne, il développe le Jiu-Jitsu Brésilien dans la région en proposant une pratique adaptée à chacun,
          simple et efficace. Son expérience l'amène à pratiquer des stages auprès de différents clubs (Melun, Pythagore Bordeaux, CPB Rennes, etc) pour transmettre son savoir-faire.
        </p>
        <div  className={`professsorInfo ${styles.professsorInfo}`}>
          <p className={styles.info1}><i class="fa-solid fa-medal"></i>JJB</p>
          <p className={styles.info}><i class="fa-solid fa-circle-play"></i>152 vidéos</p>
          <p className={styles.info}><i class="fa-solid fa-users-line"></i>Tous niveaux</p>
        </div>
      </div>

      <div><h1 className={`h1prof ${styles.h1prof}`}>Aurélie Le Vern</h1></div>
      <div  className={`professsor ${styles.professsor}`}>
        <img className={`Thiago ${styles.Thiago}`} src="aurelie.jpeg" alt="Thiago" />
        <p className={`pJJB ${styles.pJJB}`}>
        Aurélie Le Vern est une ceinture noire française , issu de l’école Six Blades jiu-jitsu de Guyane. Formée par Gonsalves Tyrone, et de lignée des frères Ribeiro. 
        Elle combat en -74kg en kimono et jusqu’en -65kg en grappling.  <br /> <br />
        Elle est 3ème aux Pan et Mondial 2023 puis se couronne Championne d’Europe IBJJF en 2024, 
        et Double Gold au Championnat d’Europe NoGi. Elle remporte aussi les Trials Europe-Afrique en 2024 et se qualifie pour les World ADCC, 
        les « olympiades du grappling ». Aurélie a également combattu à l’UFC Fight Pass Invitational 7, et remporte l’ADXC 7 contre la championne en titre de l’ADCC. <br /> <br />
         Dans les ceintures de couleurs, Aurélie a un palmarès impressionnant : IBJJF World Champion (bleue, violette, marron), IBJJF European Champion (en bleue et marron), 
         IBJJF Pan Champion (bleue, et double gold en marron). <br /> <br />
         Aurélie n’aime pas gagner aux points, son jiu-jitsu est agressif et en pression. 
         Par dessus comme par dessous elle cherche la domination et la finalisation de ses adversaires.
        </p>
        <div  className={`professsorInfo ${styles.professsorInfo}`}>
          <p className={styles.info1}><i class="fa-solid fa-medal"></i>JJB</p>
          <p className={styles.info}><i class="fa-solid fa-circle-play"></i>19 vidéos</p>
          <p className={styles.info}><i class="fa-solid fa-users-line"></i>Tous niveaux</p>
        </div>
      </div>

      <div><h1 className={`h1prof ${styles.h1prof}`}>Kévin Lépine</h1></div>
      <div  className={`professsor ${styles.professsor}`}>
        <img className={`Thiago ${styles.Thiago}`} src="kevin.jpeg" alt="Thiago" />
        <p className={`pJJB ${styles.pJJB}`}>
        Kevin Lépine est le responsable de la section jiu-jitsu brésilien au sein du Dojo Pays de Lorient, en Bretagne. <br /> <br />
        Il a commencé le jjb au Cercle Paul Bert à Rennes, avec Fabrice Infanty et Olivier Quéré, avant d'intégrer l'académie Pythagore d'Emmanuel Fernandez. 
        Kevin travaille avec le lapel, pour pouvoir lutter contre les plus gros gabarit, et pour compenser la puissance physique de ses adversaires. <br /> <br />
        Depuis plus de 10 ans, il développe un jeu basé sur le lapel grip,le shin to shin et la garde de la riva.
        </p>
        <div  className={`professsorInfo ${styles.professsorInfo}`}>
          <p className={styles.info1}><i class="fa-solid fa-medal"></i>JJB</p>
          <p className={styles.info}><i class="fa-solid fa-circle-play"></i>22 vidéos</p>
          <p className={styles.info}><i class="fa-solid fa-users-line"></i>Tous niveaux</p>
        </div>
      </div>

      <div><h1 className={`h1prof ${styles.h1prof}`}>Félicien de Boerdere</h1></div>
      <div  className={`professsor ${styles.professsor}`}>
        <img className={`Thiago ${styles.Thiago}`} src="feli2.jpg" alt="Félicien" />
        <p className={`pJJB ${styles.pJJB}`}>
        Kinésithérapeute du sport depuis 12 ans, Félicien s'est toujours formé dans le domaine de la réathlétisation et du renforcement musculaire. Passionné, il a toujours eu à cœur de transmettre ses connaissances aux sportifs, de façon simple et pédagogique. <br /> <br />
        Félicien permet aux sportifs d'atteindre, ou de retrouver, leur meilleur niveau de performance. Après avoir exercé dans différents cabinets, il a co-créé un cabinet dédié à la prise en charge des sportifs (rugbyman, crossfiteur, boxeur, judoka, etc). Félicien a également fondé, avec un préparateur physique, un pôle santé et performance dans un club de rugby, et a participé à sa montée en fédéral 2.<br /><br />
        Depuis sa découverte du jiu-jitsu, il accompagne des combattants dans leur développement. <br />  <br />
        Sport pratiqué : JJB, rugby, course à pied, CrossFit, renforcement musculaire.
        </p>
        <div  className={`professsorInfo ${styles.professsorInfo}`}>
          <p className={styles.info1}><i class="fa-solid fa-medal"></i>Renforcement</p>
          <p className={styles.info}><i class="fa-solid fa-circle-play"></i>91 vidéos</p>
          <p className={styles.info}><i class="fa-solid fa-users-line"></i>Tous niveaux</p>
        </div>
      </div>
    </div>
  );
}
